'use strict';

const scroll = {
  
  getOffsets: () => {
    const doc = document;
    const w = window;
    let x, y, docEl;
    
    if (typeof w.pageYOffset === 'number') {
        x = w.pageXOffset;
        y = w.pageYOffset;
    } else {
        docEl = (doc.compatMode && doc.compatMode === 'CSS1Compat') ? doc.documentElement : doc.body;
        x = docEl.scrollLeft;
        y = docEl.scrollTop;
    }

    return {x:x, y:y};
  },
}

module.exports = scroll;

/*
 * This file imports all of the logic
 * for the application and inokes it.
 */

'use strict';

import jQuery from "jquery";

window.$ = window.jQuery = jQuery;
require('../../../bower_components/bootstrap-sass/assets/javascripts/bootstrap');
require('../../../bower_components/slicknav/jquery.slicknav');

import 'babel-polyfill';
import all from './pages/all';

// import './utils/playVideos';

all.init($('#nav'));

"use strict";

import $ from "jquery";
import scroll from "../utils/scroll";

// cache selectors for better performance
const dom = {
  window: $(window),
  body: $("body"),
  header: $("header"),
  subnav: $(".subnav"),
  subnavFeatured: $(".subnav .featured"),
  subnavExpertise: $(".subnav .expertise"),
  subnavSearch: $(".subnav .search"),
  expertiseOptions: $(".subnav .expertise-options li"),
  workItems: $(".template-work .work-item"),
  workSearch: $(".template-work .subnav #keyword"),
  searchForm: $(".template-work #search"),
  isWorkPage: $(".template-work").length,
  isProjectPage: $(".template-project").length
};

//$('#nav'), $('#nav .top-nav .search a'), $('#nav #search')

const all = {
  init: slicknav => {
    all.nav(slicknav);
    dom.subnavFeatured.on("click", () => all.all());
    dom.subnavExpertise.on("click", () => all.expertise());
    dom.subnavSearch.on("click", () => all.search());
    dom.expertiseOptions.on("click", event => all.expertiseClick(event));
    dom.workSearch.on("input", event => all.searchChange(event));
    dom.searchForm.submit(e => e.preventDefault());

    all.work();
  },

  nav: slicknav => {
    slicknav.slicknav({
      label: "",
      brand: '<a href="/" class="logo">AMWUA</a>',
      allowParentLinks: true
    });

    dom.window.scroll(() =>
      dom.body.toggleClass("scrolled", scroll.getOffsets().y > 120)
    );
  },

  // search: (searchButton, searchForm) => {
  //   searchButton.on('click', () => searchForm.toggleClass('selected'));
  // },

  all: () => {
    if (dom.isProjectPage) {
      window.location.href = CS.basePath + "work";
    }

    all.removeAllSelected();
    // dom.subnav.toggleClass("selected-featured");
    all.setFeatured();
  },

  work: () => {
    if (dom.isWorkPage) {
      let expertise = all.getParameterByName("expertise");
      let keyword = all.getParameterByName("keyword");

      // dom.workItems.fadeOut(10).fadeIn(10);

      if (expertise) {
        dom.subnavExpertise.click();
        all.setExpertise(expertise);
      } else if (keyword) {
        all.search();
        $("#keyword").val(keyword);
        all.setSearch(keyword);
      } else {
        all.setFeatured();
      }
    }
  },

  expertise: () => {
    all.removeAllSelected();
    all.showAllWork();
    dom.subnav.toggleClass("selected-expertise");
  },

  expertiseClick: event => {
    let expertise = $(event.target);
    let className = expertise.attr("class");

    if (dom.isProjectPage) {
      window.location.href = CS.basePath + "work?expertise=" + className;
    }

    all.setExpertise(className);
  },

  setExpertise: className => {
    dom.workItems.siblings('[data-expertise*="' + className + '"]').fadeIn(400);
    dom.workItems.not('[data-expertise*="' + className + '"]').fadeOut(400);

    dom.expertiseOptions.removeClass("selected");
    dom.expertiseOptions
      .parent()
      .find("." + className)
      .addClass("selected");
  },

  setFeatured: () => {
    dom.workItems.siblings('[data-featured*="yes"]').fadeIn(400);
    dom.workItems.not('[data-featured*="yes"]').fadeOut(400);
    dom.subnav.toggleClass("selected-featured");
  },

  search: () => {
    all.removeAllSelected();
    all.showAllWork();
    dom.subnav.toggleClass("selected-search");
    dom.workSearch.focus();
    setTimeout(5000, () => dom.workSearch.focus());
  },

  searchChange: event => {
    all.setSearch($(event.target).val());
  },

  setSearch: keyword => {
    keyword = keyword.toLowerCase();

    if (dom.isProjectPage) {
      window.location.href = CS.basePath + "work?keyword=" + keyword;
    }

    if (keyword === "") {
      all.showAllWork();
    } else {
      dom.workItems.siblings('[data-keywords*="' + keyword + '"]').fadeIn(400);
      dom.workItems.not('[data-keywords*="' + keyword + '"]').fadeOut(400);
    }
  },

  showAllWork: () => {
    dom.workItems.fadeIn(400);
  },

  removeAllSelected: () => {
    dom.subnav
      .removeClass("selected-expertise")
      .removeClass("selected-search")
      .removeClass("selected-featured");
    dom.expertiseOptions.removeClass("selected");
    dom.workSearch.val("");
    // all.showAllWork();
  },

  getParameterByName: name => {
    var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }
};

module.exports = all;
